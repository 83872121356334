import { createApp } from 'vue'

import axios from 'axios';
window.axios = axios;

import router from './router'
import store from './store'

import App from './App.vue'

import moshaToast from 'mosha-vue-toastify'
import MasonryWall from '@yeger/vue-masonry-wall'

// import Datepicker from 'vue3-date-time-picker';
import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp({})

app.config.globalProperties.$axios = axios;
app.use(router)
app.use(store)
app.use(moshaToast)
app.use(MasonryWall)
app.component('App', App)
app.component('Datepicker', Datepicker)
app.mount('#app')
